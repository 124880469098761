<template>
<div id="buttonBlock">
	<!-- 0: Choose cocktail: Marg / Spritz -->
	<div v-if="step === 0" class="button-wrapper">
		<Button v-for="(item, index) in drinks" :key="index" @click="setDrinkID(index)" @mouseenter="highlightDrink(item.type)" @mouseleave="highlightDrink(null)" ref="btns">{{ item.label }}</Button>
	</div>

	<!-- 1: Choose base -->
	<div v-if="step === 1" class="button-wrapper">
		<Button v-for="(item, index) in alcohols[drinkID]" :key="index" @click="setAlcoholID(index)" ref="btns">{{ item.label }}</Button>
	</div>

	<!-- 2: Choose garnish -->
	<div v-if="step === 2" class="button-wrapper">
		<Button v-for="(item, index) in garnishes[drinkID][alcoholID]" :key="index" @click="setGarnishID(index)" ref="btns">{{ item.label }}</Button>
	</div>

	<!-- 3: End-screen -->
	<div v-if="step === 3" class="button-wrapper">
		<ButtonBuy @click="purchaseClick" ref="btns">{{ $t('misc.purchase') }}</ButtonBuy>
		<div class="clickText" @click="viewRecipeClick" ref="link1">
			<img class="fdl-white" src="/images/fleur-de-lis-white.svg">
			<img class="fdl-green" src="/images/fleur-de-lis-green.svg">
			{{ $t('misc.view_recipe') }}
			<img class="fdl-white flip" src="/images/fleur-de-lis-white.svg">
			<img class="fdl-green flip" src="/images/fleur-de-lis-green.svg">
		</div>
		<div class="clickText" @click="restartClick" ref="link2">
			<img class="fdl-white" src="/images/fleur-de-lis-white.svg">
			<img class="fdl-green" src="/images/fleur-de-lis-green.svg">
			{{ $t('misc.restart') }}
			<img class="fdl-white flip" src="/images/fleur-de-lis-white.svg">
			<img class="fdl-green flip" src="/images/fleur-de-lis-green.svg">
		</div>
	</div>
</div>
</template>

<script>
import Button from '@/components/Button';
import ButtonBuy from '@/components/ButtonBuy';
import gsap from 'gsap';
import { mapState } from 'vuex';

const COCKTAILS = ["margarita", "spritz"];
const BASES = [["reposado", "blanco"], ["rose", "sparkling"]];
const GARNISHES = [
	[ ["salt", "tajin"], ["salt", "no-salt"] ],
	[ ["grapefruit", "florals"], ["lime", "lemon-florals"] ]
];

export default {
	components: {Button, ButtonBuy}, 
	props: {
		drinkID: { type: Number},	// aka: Cocktail
		alcoholID: { type: Number},	// aka: Base
		garnishID: { type: Number},	// actually garnish
		step: { type: Number },
		prov: { type: String },
		lang: { type: String },
	},
	methods: {
		highlightDrink(type) {
			if (this.isPortrait) return;
			this.$emit('highlightDrink', type);
		},
		// Chooses cocktail
		setDrinkID(index) {
			this.hideButtons();
			gsap.delayedCall(0.5, () => {
				this.$emit("setDrinkID", index);
			});
			this.analyticsChoose(COCKTAILS[index]);
		},
		// Chooses base
		setAlcoholID(index) {
			this.hideButtons();
			gsap.delayedCall(0.5, () => {
				this.$emit("setAlcoholID", index);
			});
			this.analyticsChoose(BASES[this.drinkID][index]);
		},
		setGarnishID(index) {
			this.hideButtons();
			gsap.delayedCall(0.5, () => {
				this.$emit("setGarnishID", index);
			});
			this.analyticsChoose(GARNISHES[this.drinkID][this.alcoholID][index]);
		},
		purchaseClick() {
			this.analyticsCTA("purchase");
			// Default purchase URL
			let url = this.$t('purchase');

			// Specific Canadian province URL
			if (this.prov) {
				url = this.$t(`purchaseCA.${this.prov}`);
			}

			window.open(url, "_blank");
		},
		viewRecipeClick() {
			this.analyticsCTA("view-recipe");
			this.$emit("viewRecipe");
		},
		restartClick() {
			this.analyticsCTA("restart");
			this.$emit("restart");
		},
		showButtons() {
			if(this.$refs.btns.length) {
				this.$refs.btns.forEach((btn, i) => btn.show(i * 0.1));
			} else {
				this.$refs.btns.show(0);
			}
		},
		hideButtons() {
			if(this.$refs.btns.length) {
				this.$refs.btns.forEach((btn) => btn.hide());
			} else {
				this.$refs.btns.hide();
			}
		},
		// Track end-screen CTAs
		analyticsCTA(label) {
			gtag("event", "click", {
				"event_category": "cta",
				"event_label": label
			});
		},
		// Track drink selections
		analyticsChoose(label) {
			gtag("event", "click", {
				"event_category": "choose",
				"event_label": label
			});
		}
	},
	watch: {
		step(newVal, oldVal) {
			if(newVal > oldVal) {
				this.$nextTick(() => {

					this.showButtons();

					if (this.step === 0 && this.isPortrait && this.lang !== 'it' && this.lang !== 'fr-fr') {
						setTimeout(() => this.$emit('highlightDrink', 'margarita'), 1000);
						setTimeout(() => this.$emit('highlightDrink', null), 2000);
						setTimeout(() => this.$emit('highlightDrink', 'spritz'), 3000);
						setTimeout(() => this.$emit('highlightDrink', null), 4000);
						gsap.to(this.$refs.btns[0].$el, { scale: 1.05, delay: 1, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[1].$el, { scale: 0.95, opacity: 0.5, delay: 1, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[0].$el, { scale: 1, delay: 2, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[1].$el, { scale: 1, opacity: 1, delay: 2, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[0].$el, { scale: 0.95, opacity: 0.5, delay: 3, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[1].$el, { scale: 1.05, delay: 3, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[0].$el, { scale: 1, opacity: 1, delay: 4, duration: 0.25, ease: 'sine.out' });
						gsap.to(this.$refs.btns[1].$el, { scale: 1, delay: 4, duration: 0.25, ease: 'sine.out' });
					}

				});
			}
		}
	},
	computed: {
		drinks() {
			return this.$t('home.questions.drink');
		},
		alcohols() {
			return this.$t('home.questions.alcohol');
		},
		garnishes() {
			return this.$t('home.questions.garnish');
		},
		...mapState({
			isPortrait: state => state.isPortrait,
		}),
	}
}
</script>

<style lang="scss">
@import '@/styles/mediaqueries';
@import '@/styles/variables';
#buttonBlock .button-wrapper {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	a {
		display: block;
		width: 70%;
		margin-bottom: 18px;
		text-decoration: none;
		.Button {
			width: 100%;
		}
		.ButtonBuy {
			width: 100%;
		}
	}
	button {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	.clickText {
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		width: 250px;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 12px;
		padding-top: 0%;
		cursor: pointer;

		color: color(Teal);

		img.flip {
			transform: scaleX(-1);
		}

		img.fdl-white {

			display: none;

			@media (max-aspect-ratio: 1/1) {
				display: inline;
			}
		
		}

		img.fdl-green {
			@media (max-aspect-ratio: 1/1) {
				display: none;
			}
		}
    
		@media (max-aspect-ratio: 1/1) {
			color: color(White);
			width: 200px;
		}
		
		&:hover {
			text-decoration: underline;
			text-underline-position: under;
		}

		@include tablet-up {
			padding-top: 20px;
			font-size: 20px;
		}
	}

	// Landscape
	@media (min-aspect-ratio: 1/1){
		position: static;
		margin-top: 40px;
		button {
			margin-bottom: 20px;
		}
	}
}
</style>