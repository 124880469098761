// Modules.
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        PickDrinkChoice: 'default',
        PickBaseChoice: 'default',
        PickGarnishChoice: 'default',
        CurrentHeader: 'default',
        ResultDrink: 'default',
        isPortrait: false,
    },
    mutations: {
        SET_Drink(state, PickDrinkChoice) {
            state.PickDrinkChoice = PickDrinkChoice
        },
        SET_Base(state, PickBaseChoice) {
            state.PickBaseChoice = PickBaseChoice
        },
        SET_Garnish(state, PickGarnishChoice) {
            state.PickGarnishChoice = PickGarnishChoice
        },
        SET_CurrentHeader(state, CurrentHeader) {
            state.CurrentHeader = CurrentHeader
        },
        SET_ResultDrink(state, ResultDrink) {
            state.ResultDrink = ResultDrink
        },
        setIsPortrait(state, isPortrait) {
            state.isPortrait = isPortrait;
        }
    },
    actions: {
        setIsPortrait({ commit }, isPortrait) {
            commit('setIsPortrait', isPortrait);
        }
    },
    modules: {
    },
});
