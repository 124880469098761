<template>
	<div id="sectionHeader">
		<div id="mobileGradient" :class="{visible: index >= 0}"/>
		<!-- 0: St germain presents -->
		<Transition name="step" appear @after-enter="onIntroComplete">
			<div v-if="index === 0">
				<img class="imgMobile" :src="$t('misc.headers.intro_1')" alt="">
				<h1>{{$t('misc.desktop_headers.intro_1a')}}</h1>
				<h2>{{$t('misc.desktop_headers.intro_1b')}}</h2>
			</div>
		</Transition>
		<!-- 1: Le cocktail generator -->
	    <Transition name="step" appear @after-enter="onStep1Complete">
			<div v-if="index === 1">
				<img class="imgMobile" :src="$t('misc.headers.intro_2')" alt="">
				<h1>{{$t('misc.desktop_headers.intro_2a')}}</h1>
				<h2>{{$t('misc.desktop_headers.intro_2b')}}</h2>

				<!-- GetStarted button only on when skipping margaritas -->
				<Button v-if="skipMargs" @click="onGetStartedClick" ref="getStartedBtn">{{ $t('getStarted') }}</Button>
			</div>
		</Transition>
		<!-- 2: Choose your cocktail -->
		<Transition name="step" appear @after-enter="onIntroComplete">
			<div v-if="index === 2">
				<img class="imgMobile" :src="$t('misc.headers.intro_3')" alt="">
				<h1>{{$t('misc.desktop_headers.intro_3a')}}</h1>
				<h2>{{$t('misc.desktop_headers.intro_3b')}}</h2>
			</div>
		</Transition>
		<!-- 3: Choose your base -->
		<Transition name="step" appear @after-enter="onIntroComplete">
			<div v-if="index === 3">
				<img class="imgMobile" :src="$t('misc.headers.base')" alt="">
				<h1>{{$t('misc.desktop_headers.customize_a')}}</h1>
				<h2>{{$t('misc.desktop_headers.customize_b')}}</h2>
			</div>
		</Transition>
		<!-- 4: Choose your garnish -->
		<Transition name="step" appear @after-enter="onIntroComplete">
			<div v-if="index === 4">
				<img class="imgMobile" :src="$t('misc.headers.garnish')" alt="">
				<h1>{{$t('misc.desktop_headers.garnish_a')}}</h1>
				<h2>{{$t('misc.desktop_headers.garnish_b')}}</h2>
			</div>
		</Transition>
		<!-- 5: The St~Germain XXX Drink Gasnished with YYY -->
		<Transition name="step" appear @after-enter="onIntroComplete">
			<div v-if="index === 5 && recipeHeader && garnishHeader" class="endScreen">
				<img :src="recipeHeader" class="imgCocktailName">
				<h3>{{ garnishHeader }}</h3>
			</div>
		</Transition>
	</div>
</template>

<script>
import Button from '@/components/Button';
import gsap from 'gsap';

export default {
	name: 'Headers',
	components: {Button},
	data() {
		return {
			index: -2,
		}
	},
	props: {
		recipeHeader: { type: String },
		garnishHeader: { type: String },
		skipMargs: { type: Boolean }
	},
	methods: {
		startIntro() {
			this.index = 0;
		},
		setIndex(index) {
			this.index = index;
		},
		onIntroComplete() {
			if (this.index < 2) {
				setTimeout(() => this.setIndex(Math.min(this.index + 1 , 5)), 500);
			} else {
				this.$emit('onAfterEnter', this.index);
			}
		},
		onStep1Complete() {
			// Pause flow and show "getStarted" button when skipping margaritas
			if (this.skipMargs) {
				this.$refs.getStartedBtn.show();
			}
			// Otherwise, continue flow
			else {
				this.onIntroComplete();
			}
		},
		// GetStarted click auto-selects Spritz
		onGetStartedClick() {
			this.$refs.getStartedBtn.hide();
			this.setIndex(-1);
			this.$emit('onSkipMargsClick');
		}
	}
}
</script>

<style lang="scss">
@import "../styles/variables";
@import "../styles/mediaqueries";
#sectionHeader {
	position: relative;
	text-transform: uppercase;
	z-index: $zHeaders;
	margin-top: -40px;
	text-align: center;
	text-shadow: $textGlow;
	pointer-events: none;
	#mobileGradient {
		display: none;
		z-index: - 1;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(#0A1428, 0.5), rgba(#0A1428, 0.0));
		mix-blend-mode: multiply;
		opacity: 0;
		transition: opacity 1s linear;
		&.visible {
			opacity: 1;
		}
	}
	.endScreen {
		margin-top: -200px;
		padding-bottom: 20px;
		.imgCocktailName {
			width: 40vw;
			max-width: 450px;
			height: auto;
		}
	}
	.imgMobile {
		display: none;
		margin: 0 auto;
		max-width: 60vw;
		max-height: 25vw;
	}
	.Button {
		margin-top: 5vh;
	}
	// Portrait layout changes
	@media (max-aspect-ratio: 1/1) {
		position: absolute;
		top: 0;
		padding-top: 20%;
		width: 100%;
		margin: 0;
		#mobileGradient {
			display: block;
		}
		.imgMobile {
			display: block;
		}
		.endScreen {
			margin-top: 0;
			padding-bottom: 0;
			.imgCocktailName {
				width: 60vw;
			}
		}
		h1, h2 {
			display: none;
		}
		h3 {
			font-size: 20px;
			margin-top: 20px;
		}
		.Button {
			margin-top: 25vh;
		}
	}

	// Typography
	h1 {
		font-size: 100px;
		line-height: 1em;
		text-shadow: $textGlowGreen;
	}
	h2 {
		font-size: 72px;
		line-height: 1em;
		text-shadow: $textGlowGreen;
	}
	h3 {
		white-space: pre;
		font-size: 40px;
		line-height: 1em;
		text-shadow: $textGlowGreen;
	}
	// Font auto-scales at 1182px and below
	@media (max-width: $screen-small-desktop) {
		h1 {
			font-size: 7vw;
		}
		h2 {
			font-size: 5vw;
		}
		h3 {
			font-size: 4vw;
		}
	}

	// Intro/outro Animations
	.step-enter {
		opacity: 0;
		transform: translateY(15%);
		h2 {
			transform: translateY(15%);
		}
	}
	.step-leave-to {
		opacity: 0;
		transform: translateY(-20%);
		h2 {
			transform: translateY(20%);
		}
	}
	.step-enter-active {
		transition: opacity 0.8s $ezOut 1s, transform 0.8s $ezOut 1s;
		h2 {
			transition: transform 1.2s $ezOut 1s;
		}
	}
	.step-leave-active {
		transition: opacity 0.5s $ezIn, transform 0.5s $ezIn;
		h2 {
			transition: transform 0.5s $ezIn;
		}
	}
}
</style>