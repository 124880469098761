<template>
	<div id="videoBox">
		<video autoplay playsinline muted ref="video">
			<source src="/video/st-germain-compressed-v3.mp4" type="video/mp4">
		</video>
		<div :class="{ vidPoster: true, hidden: firstPlay, default: true }" :style="{ backgroundImage: getPosterImage }"></div>
		<Transition name="fade">
			<div v-if="highlightedDrink === 'spritz'" :class="{ vidPoster: true, hidden: firstPlay, spritz: true }"></div>
		</Transition>
		<Transition name="fade">
			<div v-if="highlightedDrink === 'margarita'" :class="{ vidPoster: true, hidden: firstPlay, margarita: true }"></div>
		</Transition>
	</div>
</template>

<script>
import gsap from 'gsap';

export default {
	name: 'Video2',
	props: {lang: String},
	data() {
		return {
			firstPlay: false,
			highlightedDrink: null,
		}
	},
	mounted() {
		this.$refs.video.pause();
		this.$refs.video.currentTime = 0;
	},
	methods: {
		playSegment(from, to, animations = null, callback) {
			gsap.delayedCall(1.5, () => {
				this.firstPlay = true;
			});
			this.$refs.video.currentTime = from;
			this.$refs.video.play();

			let baseIn     = false;
			let baseOut    = false;
			let liqueurIn  = false;
			let liqueurOut = false;
			let mixerIn    = false;
			let mixerOut   = false;

			const loop = () => {

				if (animations) {
					switch (true) {
						case (this.$refs.video.currentTime >= animations.base[0] && !baseIn):
							baseIn = true;
							callback('base-in');
							break;
						case (this.$refs.video.currentTime >= animations.base[1] && !baseOut):
							baseOut = true;
							callback('base-out');
							break;
						case (this.$refs.video.currentTime >= animations.liqueur[0] && !liqueurIn):
							liqueurIn = true;
							callback('liqueur-in');
							break;
						case (this.$refs.video.currentTime >= animations.liqueur[1] && !liqueurOut):
							liqueurOut = true;
							callback('liqueur-out');
							break;
						case (this.$refs.video.currentTime >= animations.mixer[0] && !mixerIn):
							mixerIn = true;
							callback('mixer-in');
							break;
						case (this.$refs.video.currentTime >= animations.mixer[1] && !mixerOut):
							mixerOut = true;
							callback('mixer-out');
							break;
					}
				}

				if (this.$refs.video.currentTime >= to) {
					this.$refs.video.pause();
					callback('done');
				} else {
					requestAnimationFrame(loop);
				}

			}

			requestAnimationFrame(loop);
		},
		restart() {
			this.firstPlay = false;
		},
		highlightDrink(type) {
			this.highlightedDrink = type;
		},
	},
	computed: {
		getPosterImage() {
			
			let imgUrl = "/images/intro-default.jpg";

			switch (this.lang) {
				case "it":
					imgUrl = "/images/intro-italy.jpg";
				break;
				case "fr-fr":
					imgUrl = "/images/intro-france.jpg";
				break;
			}

			return `url("${imgUrl}")`;
		}
	}
}
</script>

<style lang="scss" scoped>
#videoBox {
	pointer-events: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	video {
		object-fit: cover;
		object-position: center center;

		width: 100%;
		height: 100%;
	}
	.vidPoster {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		background: center center / cover no-repeat;
		// opacity: 1;
		// transition: opacity 0.5s linear;
		&.hidden {
			// opacity: 0;
			visibility: hidden;
		}
	}
	.vidPoster.spritz,
	.vidPoster.margarita {
		z-index: 0;
	}
	.vidPoster.spritz {
		background-image: url('/images/intro-spritz.jpg');
	}
	.vidPoster.margarita {
		background-image: url('/images/intro-margarita.jpg');
	}
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 250ms ease;
}

</style>