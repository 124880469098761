<template>
    <section id="Audio">
       <audio ref="stgAudio" loop>
            <source src="/audio/stg-audio.mp3" type="audio/mpeg">
        </audio>
    </section>
</template>

<script>
    export default {
        name: 'Audio',
        mounted() {
            
        },
        props: {
            playAudio: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            playAudio() {
                const audio = this.$refs.stgAudio;
                if (this.playAudio) {
                    audio.play()
                }
                else {
                    audio.pause()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/styles/_mediaqueries.scss';

    #Audio {
        position: absolute;
        background-color: transparent;
        width:100%;
        height: 100%;

            .fullscreen-video {
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
                }

    @include tablet-less {
            @media (orientation: landscape) {
                display: none;
            } 
        }
    }

</style>
