<template>
	<section id="Controls" ref="controls">
		<div id="lang-selector" v-if="(lang === 'fr-ca' || lang === 'en-ca')">
			<span class="lang" :class="{active: lang === 'en-ca'}" @click="setLang('en-ca')">EN</span>
			<span>/</span>
			<span class="lang" :class="{active: lang === 'fr-ca'}" @click="setLang('fr-ca')">FR</span>
		</div>
		<div class="volume" @click="toggleVolume">
			<img v-if="playAudio" :src="`/images/sound-on${ isPortrait ? '' : '-black' }.svg`" alt="">
			<img v-else :src="`/images/sound-off${ isPortrait ? '' : '-black' }.svg`" alt="">
		</div>
		<Audio :play-audio="playAudio" />
	</section>
</template>

<script>
	
	import gsap from 'gsap'
	import Audio from '@/components/Audio';
	import Volume from '@/components/svgs/Volume';
	import { mapState } from 'vuex';

	export default {
		name: 'Controls',
		props: {
			lang: { type: String },
			prov: { type: String },
		},
		data() {
			return {
				playAudio: false
			}
		},
		methods: {
			setLang(lang) {
				let params = `?lang=${lang}`;
				if (this.prov) {
					params += `&prov=${this.prov}`;
				}
				window.location.search =  params;
			},
			fadeIn() {
				gsap.to(this.$refs.controls, 1.2, { opacity: 1, delay: 3, ease: 'power3.out'});
			},
			toggleVolume () {
				this.playAudio = !this.playAudio
			}
		},
		mounted() {
			this.fadeIn();
		},
		computed: {
			...mapState({
				isPortrait: state => state.isPortrait,
			}),
		},
		components: { Volume, Audio }
	}

</script>

<style lang="scss">
@import '@/styles/_mediaqueries.scss';
@import "../styles/variables";
#Controls {
	z-index: $zControls;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 12px 18px;
	pointer-events: none;
	opacity: 0;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	#lang-selector {
		text-transform: uppercase;
		font-size: 16px;
		line-height: 150%;
		text-align: center;
		color: #FFFFFF;
		pointer-events: auto;

		span {
			display: inline-block;
			padding: 4px;
		}
		.lang {
			cursor: pointer;
			opacity: 0.8;
			&.active {
				pointer-events: none;
				text-decoration: underline;
				opacity: 1.0;
			}
		}
	}
	.volume {
		padding-left: 15px;
		pointer-events: auto;
		cursor: pointer;
		img {
			height: 28px;
		}
	}
	//////////////// Portrait (mobile)
	@media (max-aspect-ratio: 1/1){
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}
</style>
