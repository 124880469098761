<template>
	<button class="Button" :class="{ hovered, visible }" @click="$emit('click')" @mouseenter="$emit('mouseenter')" @mouseleave="$emit('mouseleave')" @mouseover="hovered = true" @mouseout="hovered = false" ref="self">
		<span class="textSlot">
			<slot />
		</span>
	</button>
</template>

<script>
import gsap from 'gsap';

export default {
	name: 'Button',
	data() {
		return {
			hovered: false,
			visible: false
		}
	},
	mounted() {
		gsap.set(this.$refs.self, {scale: 1.2, opacity: 0});
	},
	methods: {
		show(delay) {
			gsap.to(this.$refs.self, {opacity: 1, scale: 1.0, duration: 0.5, delay, clearProps: true, onComplete: () => {
				this.visible = true;
			}});
		},
		hide() {
			this.visible = false;
			gsap.to(this.$refs.self, {opacity: 0, scale: 1.2, duration: 0.5});
		}
	}
}
</script>

<style lang="scss">
@import '../styles/variables';
@import '../styles/mediaqueries';

.Button {
	position: relative;
	appearance: none;
	border: none;
	outline: none;
	width: 80%;
	max-width: 400px;
	margin: 0 auto;
	height: 60px;
	font-size: 16px;
	// color: color(DarkBlue);
	color: color(White);
	background: transparent;
	box-shadow: none;
	border-radius: 100px;
	// background: linear-gradient(to right, transparent 10%, #fff 10%, #fff 90%, transparent 90%);
	background-image: url('/images/button-idle.png');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	pointer-events: none;



	&.visible {
		pointer-events: auto;
		cursor: pointer;
		// transition: transform 0.25s ease, box-shadow 0.25s ease;
		transition: box-shadow 0.25s ease, background-image 250ms ease, color 250ms ease;
	}
	&.hovered {
		// box-shadow: 0 0 20px #fff;
		background-image: url('/images/button-active.png');
		color: color(DarkBlue);
	}

	@media (max-aspect-ratio: 1/1) {
		background-image: url('/images/button-active.png');
		color: color(DarkBlue);
	}

	.textSlot {
		z-index: 1;
	}
	.endcapSVG {
		position: absolute;
		top: 0;
		height: 100%;
		&.left {
			left: 0;
		}
		&.right {
			right: 0;
		}
		rect {fill: #fff;}
	}
	@include tablet-up {
		font-size: 20px;
	}
}
</style>