<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>

/*
-- Available locales: --
en (default)
en-uk
en-ca
fr-fr
fr-ca
it
*/
export default {
    name: 'app',
    created() {
    	// Defaults to French in Quebec, otherwise English
    	const defaultLang = (this.$route.query.prov === "QB") ? "fr-ca" : "en";
        this.$root.$i18n.locale = this.$route.query.lang ?? defaultLang;
    }
}

</script>