<template>
	<div class="mobile-frame" :class="{ endState: endState }">
		<img class="" :src="isGreen ? '/images/frames/top-white.png' : '/images/frames/top-white.png'" alt="">
		<img class="stretch" :src="isGreen ? '/images/frames/middle-white.png' : '/images/frames/middle-white.png'" alt="">
		<img v-if="!isGreen" class="" src="/images/frames/bottom-white.png" alt="">
		<img v-if="!isGreen" class="logo" :class="{ french: lang === 'fr-fr' }" src="/images/st-germain.png" alt="St. Germain">
	</div>
</template>

<script>
export default {
	name: 'Frame',
	data() {
		return {
			endState: false
		}
	},
	props: {
		isGreen: { type: Boolean, default: false },
		lang: { type: String, default: "en" }
	},
	methods: {
		showCard() {
			this.endState = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.mobile-frame {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 20px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	pointer-events: none;
	img:not(.logo) {
		width: 100%;
	}

	img.stretch {
		flex: 1;
	}

	img.logo {
		position: absolute;
		bottom: calc(2.5% + 20px);
		left: 50%;
		transform: translateX(-50%);
		width: 40%;
		&.french {
			bottom: 42px;
		}
	}
	&.endState::before {
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		animation: fadeIn 1s;
		background: linear-gradient(
			0deg,
			rgba(10, 20, 40, 0.5) 0%,
			rgba(10, 20, 40, 0) 100%
		);
	}

	// Landscape
	@media (min-aspect-ratio: 1/1){
		display: none;
	}
}


</style>
