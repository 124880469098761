<template>
	<section id="Home" class="fill">
		<Controls :lang="lang" :prov="prov" />

		<!-- LEFT VIDEO COLUMN -->
		<div class="columnVideo" :class="{hidden: showRecipe}">
			<div class="inner">
				<Transition name="video" appear @after-enter="onAfterVideoIntro">
					<Video ref="video" :lang="lang" />
				</Transition>
				<Transition name="frame" appear>
					<Frame ref="mobileFrame" :lang="lang" />
				</Transition>
			</div>
			<img class="logo" ref="logo" src="/images/st-germain.png" alt="St. Germain">
		</div>

		<!-- RIGHT TEXT COLUMN -->
		<div class="columnText" ref="textColumn" :class="{hidden: showRecipe}">
			<Transition name="titleHeader" mode="out-in">
				<div v-if="showCocktailHead" class="cocktail-head">
					<img class="headimg" :src="$t('mainTitle')" />
				</div>
			</Transition>

			<Headers ref="headers"
				:recipeHeader="recipeHeader"
				:garnishHeader="garnishHeader"
				:skipMargs="skipMargs" 
				@onAfterEnter="onAfterHeaderEnter"
				@onSkipMargsClick="onSkipMargsClick"
			/>

			<!-- Mixing instructions -->
			<div v-if="alcoholID !== null && garnishID === null" class="label-wrapper">
				<Transition name="labels" mode="out-in">
					<p v-if="pourState === 'base-in'" class="glowy" v-html="alcohols[drinkID][alcoholID].pour_base"></p>
				</Transition>
				<Transition name="labels" mode="out-in">
					<p v-if="pourState === 'liqueur-in'" class="glowy" v-html="alcohols[drinkID][alcoholID].add_liqueur"></p>
				</Transition>
				<Transition name="labels" mode="out-in">
					<p v-if="pourState === 'mixer-in'" class="glowy" v-html="alcohols[drinkID][alcoholID].add_mixer"></p>
				</Transition>
			</div>

			<ButtonBlock 
				:drinkID="drinkID" :alcoholID="alcoholID" :garnishID="garnishID" :step="step" :prov="prov"
				@setDrinkID="setDrinkID" @setAlcoholID="setAlcoholID" @setGarnishID="setGarnishID"
				@viewRecipe="viewRecipeClick" @restart="restart"
				@highlightDrink="highlightDrink"
				:lang="lang"
			></ButtonBlock>

			<LegalTag :prov="prov" v-if="lang !== 'fr-fr'"></LegalTag>
		</div>
		<LegalTagFrench v-if="lang === 'fr-fr'"></LegalTagFrench>
		
		<!-- ENDSCREEN RECIPE -->
		<Recipe
			v-if="showRecipe"
			:id="$t('home.recipe_ids')[drinkID][alcoholID][garnishID]"
			:lang="lang"
			ref="recipe"
			@recipeClosed="recipeClosed"
		/>
		<div id="pinterestBtn" :class="{visible: showRecipe}" @click="trackPinterest">
			<!-- <a data-pin-do="buttonBookmark" data-pin-tall="true" :data-pin-lang="lang" href="https://www.pinterest.com/pin/create/button/">
				
			</a> -->
			<a data-pin-do="buttonBookmark" data-pin-custom="true" href="https://www.pinterest.com/pin/create/button/">
				<img src="https://pinterest-st-germain.s3.amazonaws.com/pin-it-button.png" height="35" />
				<span>{{ $t('pinSave') }}</span>
			</a>
		</div>

		<div id="devSkipToEnd" @click="devSkip" :class="{devMode}"></div>
	</section>
</template>

<script>
import preloader from 'preloader';
import Video from '@/components/Video';
import Frame from '@/components/Frame';
import Headers from '@/components/Headers';
import ButtonBlock from '@/components/ButtonBlock';
import Recipe from '@/components/Recipe';
import Controls from '@/components/Controls';
import LegalTag from '@/components/LegalTag';
import LegalTagFrench from '@/components/LegalTagFrench';
import gsap from 'gsap';
import { mapState } from 'vuex';

export default {
	name: 'Home',
	components: {
		Video,
		Frame,
		Headers,
		ButtonBlock,
		Recipe,
		Controls,
		LegalTag,
		LegalTagFrench,
	},
	data() {
		return {
			drinkID: null,	// aka: Cocktail (0: Margarita, 1: Spritz)
			alcoholID: null,// aka: Base (0: Repo/Rose, 1: Blanco/Sparkling)
			garnishID: null,	// (0, 1)
			step: -1,
			pourState: '',
			isVideoReady: false,
			showCocktailHead: false,
			isPortrait: false,
			showRecipe: false,
			devMode: false,
			lang: "en",			// Locale defaults to "en"
			prov: null,			// Canada build will need province
			skipMargs: false	// Locales for "it" & "fr-fr" will skip margarita options
		}
	},
	mounted() {
		this.isPortrait = (window.innerWidth < window.innerHeight);
		if (!this.isPortrait) {
			this.$refs.headers.startIntro();
		}

		window.addEventListener('resize', this.handleResize);
		this.handleResize();

		// Load video
		const loader = preloader({ xhrImages: false });
		loader.add('/video/st-germain-compressed-v3.mp4');
		loader.add('/images/intro-default.jpg');
		loader.add('/images/intro-margarita.jpg');
		loader.add('/images/intro-spritz.jpg');
		loader.load();
		loader.on('progress', progress => {
			if (progress > 0.25 && !this.isVideoReady) {
				this.isVideoReady = true;
			}
		});

		this.setLangAndProv();
		this.devMode = window.location.href.indexOf("localhost") > -1;

		// Init Pinterest button
		// https://assets.pinterest.com/js/pinit.js
		!function(a,b,c){var d,e,f;d="PIN_"+~~((new Date).getTime()/864e5),a[d]?a[d]+=1:(a[d]=1,a.setTimeout(function(){e=b.getElementsByTagName("SCRIPT")[0],f=b.createElement("SCRIPT"),f.type="text/javascript",f.async=!0,f.src=c.mainUrl+"?"+Math.random(),e.parentNode.insertBefore(f,e)},10))}(window,document,{mainUrl:"https://assets.pinterest.com/js/pinit_main.js"});
	},
	methods: { 
		handleResize() {
			const isPortrait = (window.innerWidth < window.innerHeight);
			this.$store.dispatch('setIsPortrait', isPortrait);
		},
		highlightDrink(type) {
			this.$refs.video.highlightDrink(type);
		},
		// Logic to set language and province from URL
		setLangAndProv() {
			const configGA = {};

			// Get province for Canada
			if(["QB", "ON", "BC"].includes(this.$route.query.prov)) {
				this.prov = this.$route.query.prov;
				configGA.prov = this.prov;
			}

			// Get lang if available
			if(this.$route.query.lang) {
				this.lang = this.$route.query.lang;

				// If some smart guy visits Canada with no province, default to BC
				if ((this.lang === "fr-ca" || this.lang === "en-ca") && !this.prov) {
					this.prov = "BC";
					configGA.prov = this.prov;
				}
			}
			// Set defaults if no language found
			else {
				// Quebec defaults to french
				if(this.prov === "QB") {
					this.lang = "fr-ca";
				}
				// Other provinces default to en-ca
				else if (this.prov) {
					this.lang = "en-ca";
				}
				else {
					this.lang = "en";
				}
			}
			// Skip margaritas if in Italy or France
			this.skipMargs = this.lang === "it" || this.lang === "fr-fr";

			configGA.lang = this.lang;
			gtag('config', 'G-R6LVTBYVSC', configGA);

		},
		// Video intro has finished
		onAfterVideoIntro() {
			if (this.isPortrait) {
				this.$refs.headers.startIntro();
			}
		},
		onAfterHeaderEnter(headerStep) {
			if (headerStep === 1) {
				this.showCocktailHead = true;
			}
			if (headerStep === 2) { 
				this.step = 0;
				this.showCocktailHead = true;
				gtag('event', 'page_view', {"page_title": "choose-cocktail"});
			}
		},
		// Clicking getStarted skips margaritas, and auto-selects spritz
		onSkipMargsClick() {
			this.showCocktailHead = true;
			this.setDrinkID(1);
		},
		// Choose your coctail (Marg, Spritz)
		setDrinkID(index) {
			this.drinkID = index;

			this.step = -1;
			this.$refs.headers.setIndex(-1);

			const data = this.$t('home.questions.drink')[this.drinkID];
			this.$refs.video.playSegment(data.timestamp.from, data.timestamp.to, null, () => {
				this.$refs.headers.setIndex(3); // Customize.
				this.step = 1;
				gtag('event', 'page_view', {"page_title": "choose-base"});
			});
		},
		setAlcoholID(index) {
			this.alcoholID = index;
			this.step = -1;
			this.$refs.headers.setIndex(-1);

			const data = this.$t('home.questions.alcohol')[this.drinkID][this.alcoholID];
			const animations = { base: data.timestamp.base, liqueur: data.timestamp.liqueur, mixer: data.timestamp.mixer };

			this.$refs.video.playSegment(data.timestamp.from, data.timestamp.to, animations, (state) => {
				if (state !== 'done') {
					this.pourState = state;
				} else {
					this.$refs.headers.setIndex(4); // Garnish.
					this.step = 2;
					gtag('event', 'page_view', {"page_title": "choose-garnish"});
				}
			});
		},
		setGarnishID(index) {
			this.showCocktailHead = false;
			this.garnishID = index;
			this.step = -1;
			this.$refs.headers.setIndex(-1);

			const data = this.$t('home.questions.garnish')[this.drinkID][this.alcoholID][this.garnishID];
			this.$refs.video.playSegment(data.timestamp.from, data.timestamp.to, null, this.showEndScreen);
		},
		showEndScreen() {
			this.$refs.headers.setIndex(5); // Cocktail.
			this.step = 3;
			this.$refs.mobileFrame.showCard();
			gtag('event', 'page_view', {"page_title": "end-screen"});
		},
		// Restart experience from end-screen
		restart() {
			this.drinkID = null;
			this.alcoholID = null;
			this.garnishID = null;

			this.$refs.video.restart();
			gsap.delayedCall(1, () => {this.showCocktailHead = true;});

			// Auto-select spritz if skipping margs
			if(this.skipMargs) {
				this.setDrinkID(1);
			}
			// Otherwise, it goes to cocktail selection
			else {
				this.step = -1;
				this.$refs.headers.setIndex(-1);
				gsap.delayedCall(1, () => {this.$refs.headers.setIndex(2);});
			}
		},
		viewRecipeClick() {
			this.showRecipe = true;
			this.$refs.headers.setIndex(-1);
			gtag('event', 'page_view', {"page_title": "recipe-screen"});
		},
		recipeClosed() {
			this.showRecipe = false;
			this.$refs.headers.setIndex(5);
		},
		// Quickly skip to end screen
		devSkip() {
			this.showCocktailHead = false;
			if (this.skipMargs) {
				this.drinkID = 1;
			} else {
				this.drinkID = Math.random() < 0.5 ? 0 : 1;
			}
			this.alcoholID = Math.random() < 0.5 ? 0 : 1;
			this.garnishID = Math.random() < 0.5 ? 0 : 1;
			this.step = 3;
			this.$refs.headers.setIndex(5);
		},
		trackPinterest() {
			gtag("event", "click", {
				"event_category": "cta",
				"event_label": "pinterest"
			});
		}
	},
	computed: {
		alcohols() {
			return this.$t('home.questions.alcohol');
		},
		...mapState({
			isPortraitStore: state => state.isPortrait,
		}),
		recipeHeader() {
			if (this.drinkID !== null && this.alcoholID !== null && this.garnishID !== null) {
				const recipeId = this.$t('home.recipe_ids')[this.drinkID][this.alcoholID][this.garnishID];
				return this.$t('recipes')[recipeId][this.isPortraitStore ? 'header_mobile' : 'header'];
			}

			return null;
		},
		garnishHeader() {
			if (this.drinkID !== null && this.alcoholID !== null && this.garnishID !== null) {
				return this.$t('home.questions.garnish')[this.drinkID][this.alcoholID][this.garnishID].headerlabel;
			}

			return null;
		},
	}
}
</script>

<style lang="scss">
@import '@/styles/mediaqueries';
sup {
	vertical-align:text-top;
	font-size:medium;
		@include tablet-up {
		   
			vertical-align:text-top;
			position: relative; top: 0.5em;
		}
	}
</style>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mediaqueries";
#devSkipToEnd {
	display: none;
	position: absolute;
	bottom: 20px;
	left: 20px;
	width: 20px;
	height: 20px;
	background: #f90;
	cursor: pointer;
	&.devMode {
		display: block;
	}
}

#Home {
	padding-top: 20%;
	background-image: url("/images/bg.jpg");
	background-size: cover;
	background-position: center center;

	.logo {
		position: absolute;
		width: 40%;
		margin-top: -24px;
		z-index: $zLogo;
		left: 50%;
		transform: translateX(-50%);
		animation: fadeIn 5s;
		@include tablet {
			margin-top: -20px;
		}

		@media (max-aspect-ratio: 1/1){
			display: none;
		}
	}

	sup {
		border: 1px solid red;
	}
	
	// Landscape
	@media (min-aspect-ratio: 1/1){
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;
		padding: 10% 0px 10% 10%;
	}
}

.columnVideo {
	transition: visibility 1s, opacity 1s linear;
	// Landscape
	@media (min-aspect-ratio: 1/1){
		width: 50.625vh;
		height: 90vh;

		transition: transform 2s ease, opacity 2s ease;
		position: relative;
		border-top-left-radius: 30vh;
		border-top-right-radius: 30vh;
		.inner {
			width: 100%;
			height: 100%;

			overflow: hidden;
			border-radius: inherit;

			transform: translateZ(0);
		}
		&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				border: 3px solid color(White);
				border-radius: inherit;
				box-shadow: 0 9px 46px rgba(255, 255, 255, 0.4),
					0 0 46px rgba(255, 255, 255, 0.4), 0 0 23px rgba(255, 255, 255, 0.4),
					inset 0 0 46px rgba(255, 255, 255, 0.5);
		}
	}
	&:after {
		animation: fadeIn 4s;
	}
	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
}

.columnText {
	transition: visibility 0.5s, opacity 0.5s linear;
	button {
		cursor: pointer;
	}
	.cocktail-head {
		text-align: center;
		animation: fadeIn 0.5s;
		margin: 0 auto;
		position: absolute;
		top: -140px;
		width: 315px;
		left: 50%;
		transform: translateX(-50%);
		display: none;
	}
	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
	// Landscape
	@media (min-aspect-ratio: 1/1){
		height: 90vh;
		position: relative;
		flex: 1;
		margin-top: 60vh;
		padding-top: 100px;
		max-width: 800px;
		.cocktail-head {
			display: block;
		}
		// Shorter than 750px tall
		@media (max-height: 750px) {
			margin-top: 45vh;
			transform: scale(0.8);
		}
		// Shorter than 650px tall
		@media (max-height: 650px) {
			margin-top: 30vh;
			transform: scale(0.8);
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.label-wrapper {
	position: absolute;
	top: 50%;
	width: 100%;
	p {
		white-space: pre;
	}
	@include tablet-up {
		position: static;
		margin-top: 20px;
	}
	@include desktop {
		position: static;
		margin-top: 40px;
	}
}

.label-wrapper {
	text-align: center;
}

.label-wrapper p {
	max-width: 70%;
	margin: 0 auto;
	font-size: 36px;
	white-space: pre-wrap;
	line-height: 1.1;

	color: color(Teal);
    
    @media (max-aspect-ratio: 1/1) {
        color: color(White);
    }

	@include tablet-up {
		font-size: 80px;
	}

	@include desktop {
		font-size: 70px;
	}
	@include tablet-up {
		font-size: 60px;
	}
}

.glowy {
	
	text-shadow: $textGlowGreen;
    
    @media (max-aspect-ratio: 1/1) {
        text-shadow: $textGlow;
    }

}

.headimg {
	margin: 0 auto;
	max-width: 90%;
	height: auto;
	text-align: center;
}

// Video
.video-enter {
	opacity: 0;
	transform: scale(1.25);
}
.video-enter-active {
	transition: opacity 2500ms cubic-bezier(0.65, 0, 0.35, 1),
		transform 2500ms cubic-bezier(0.65, 0, 0.35, 1);
}

// Frame
.frame-enter {
	transform: scale(1.25);
}
.frame-enter-active {
	transition: transform 2500ms cubic-bezier(0.65, 0, 0.35, 1) 500ms;
}

// titleHeader
.titleHeader-enter,
.titleHeader-leave-to {
	opacity: 0;
	transform: scale(0.95);
}
.titleHeader-enter-active {
	transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1), transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}
.titleHeader-leave-active {
	transition: opacity 0.8s cubic-bezier(0.33, 1, 0.68, 1), transform 0.8s cubic-bezier(0.33, 1, 0.68, 1);
}

// Labels
.labels-enter {
	opacity: 0;
	transform: translateY(25%);
}
.labels-leave-to {
	opacity: 0;
	transform: translateY(25%);
}
.labels-enter-active {
	transition: opacity 800ms cubic-bezier(0.33, 1, 0.68, 1) 800ms,
		transform 800ms cubic-bezier(0.33, 1, 0.68, 1) 800ms;
}
.labels-leave-active {
	transform: translateY(-35%);
	transition: opacity 800ms cubic-bezier(0.32, 0, 0.67, 0),
		transform 800ms cubic-bezier(0.32, 0, 0.67, 0);
}
.labels-enter-active,
.labels-leave-active {
	/* transition: opacity 800ms cubic-bezier(0.33, 1, 0.68, 1); */
}

#pinterestBtn {
	position: absolute;
	bottom: 9%;
	left: 50%;;
	cursor: pointer;
	z-index: $zPinterest;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s linear;
	transform: translateX(-50%);

	&.visible {
		opacity: 1;
		pointer-events: auto;
	}

	span {
		position: absolute;
		top: 47%;
		left: 55%;

		transform: translate(-50%, -50%);

		color: color(DarkBlue);
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 0.01em;
	}

	@media (max-aspect-ratio: 1/1) {
		bottom: 14%;
	}
}

</style>