<template>
<div id="recipeSection">
	<img id="pinterestShareable" :src="getShareable" />
	<div id="recipeFrame" class="fill" ref="node">
		<div class="recipeBackground" ref="bg"></div>
		<div class="cardFlowers left" />
		<div class="cardFlowers right" />
		<div class="frame-container fill" ref="frame">
			<Frame isGreen />
		</div>
		<div class="textContent">
			<div class="headerImage" :style="{backgroundImage: `url(${data.recipe_card.header})`}" :alt="data.name" ref="header" />
			<img class="separator top" src="/images/separator.svg" alt="">
			<div class="step-wrapper">
				<img src="/images/fleur-de-lis-green.svg">
				<h3 ref="liqueur" v-html="data.recipe_card.liqueur"></h3>
				<img src="/images/fleur-de-lis-green.svg">
			</div>
			<div class="step-wrapper">
				<img src="/images/fleur-de-lis-green.svg">
				<h3 ref="base" v-html="data.recipe_card.base"></h3>
				<img src="/images/fleur-de-lis-green.svg">
			</div>
			<div class="step-wrapper">
				<img src="/images/fleur-de-lis-green.svg">
				<h3 ref="mixer" v-html="data.recipe_card.mixer"></h3>
				<img src="/images/fleur-de-lis-green.svg">
			</div>
			<div>
				<h4 ref="prep">{{ $t('misc.preparation') }}</h4>
				<h5 ref="method">{{ data.recipe_card.method }}</h5>
			</div>
			<img class="separator bottom" src="/images/separator.svg" alt="">
			<h5 class="save">{{ $t('misc.save') }}</h5>
		</div>
		<div class="logoImage" alt="" ref="logo" />
		<div class="closeBtn" @click="close" ref="closeBtn">
			<img src="/images/close.svg" alt="">
		</div>
	</div>
	<div id="backBtn" @click="close" ref="backBtn">
		<img src="/images/back-black.svg" class="backArrow" alt="Go back">
		<span class="backTxt">{{ $t('backBtn') }}</span>
	</div>
	<div class="cornerFlowers flowersL" ref="flowersL"/>
	<div class="cornerFlowers flowersR" ref="flowersR"/>
	<div class="flowersPortrait" ref="flowersPortrait"/>
</div>
</template>

<script>
	import gsap from 'gsap';
	import Frame from '@/components/Frame';

	export default {
		name: 'Recipe',
		components: {
			Frame,
		},
		props: {
			id: { type: Number, required: true },
			lang: { type: String, required: true },
			openModal: { type: Boolean, default: false }
		},
		watch: {
			openModal() {
				if (this.openModal) {
					this.open();
					}
				else {
					close();  
				}
			}
		},
		computed: {
			data() {
				return this.$t('recipes')[this.id];
			},
			getShareable() {
				const id = this.id;
				// En-ca defaults to just "en"
				const lang = (this.lang === "en-ca") ? "en" : this.lang;
				return `/images/recipes/${lang}-${id}.jpg`;
			}
		},
		mounted() {
			gsap.set([this.$refs.bg, this.$refs.frame, this.$refs.closeBtn], { opacity: 0 });
			gsap.set([this.$refs.liqueur, this.$refs.base, this.$refs.mixer, this.$refs.prep, this.$refs.method], { opacity: 0, y: 25 });
			gsap.set([this.$refs.header,this.$refs.node], { opacity: 0 });
			gsap.set(this.$refs.logo, { opacity: 0, scale: 0.5 });
			this.open();
		},
		methods: {
			open() {
				const r = this.$refs;
				const ease = "power3.out";
				gsap.to([r.flowersL, r.flowersR, r.flowersPortrait], {x: 0, y: 0, scale: 1, opacity: 1, duration: 2, ease});
				gsap.to([r.bg, r.frame, r.node], { opacity: 1, duration: 1.6, ease });
				gsap.to(r.logo, { opacity: 1, scale: 1, duration: 1.2, delay: 0.4, ease });
				gsap.to([r.closeBtn, r.backBtn], { opacity: 1, duration: 1.5, ease });
				gsap.to([r.header, r.liqueur, r.base, r.mixer, r.prep, r.method], { opacity: 1, y: 0, duration: 1.2, delay: 0.2, stagger: 0.1, ease });
			},
			close() {
				const r = this.$refs;
				const ease = "power2.in";

				gsap.to(r.flowersL, {x: "-20%", y: "20%", opacity: 0, scale: 1.2, duration: 1.5, ease});
				gsap.to(r.flowersR, {x: "20%", y: "20%", opacity: 0, scale: 1.2, duration: 1.5, ease});
				gsap.to(r.flowersPortrait, {y: "20%", opacity: 0, scale: 1.2, duration: 1.5, ease});
				gsap.to([r.bg, r.frame, r.node], { opacity: 0, duration: 1.6, ease });
				gsap.to(r.header, { opacity: 0, duration: 1.2, ease });
				gsap.to(r.logo, { opacity: 0, scale: 0.5, duration: 1.2, delay: 0.2, ease });
				gsap.to([r.closeBtn, r.backBtn], { opacity: 0, duration: 1.2, delay: 0.4, ease: 'power3.out' });
				gsap.to([r.method, r.prep, r.mixer, r.base, r.liqueur], { opacity: 0, y: 25, duration: 1.0, stagger: 0.2, ease, onComplete: () => {
					this.$emit('recipeClosed');
				}});
			}
		}
	}
</script>

<style lang="scss">
@import '@/styles/variables';
@import '@/styles/mediaqueries';

#recipeSection {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: color(Teal);
	z-index: $zRecipe;
	#recipeFrame {
		justify-content: center;
		position: fixed;
		top: 5vh;
		left: 50%;
		width: 60vh;
		height: 90vh;
		transform: translateX(-50%);
		pointer-events: auto;

		.recipeBackground {
			// background-color: color(Mint, 0.1);
			backdrop-filter: blur(2px);
			
			background-size: cover;
			background-position: center center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-top-left-radius: 30vh;
			border-top-right-radius: 30vh;
			border: 2px solid color(White);
			box-shadow: 0 9px 46px rgba(255, 255, 255, 0.4), 0 0 46px rgba(255, 255, 255, 0.4), 0 0 23px rgba(255, 255, 255, 0.4), inset 0 0 46px rgba(255, 255, 255, 0.5);
		
			@media (max-aspect-ratio: 1/1) {
				background-image: url('/images/bg.jpg');
				border: none;
			}
		
		}

		.recipeBackground:before {

			content: '';

			position: absolute;
			top: 10px;
			right: 10px;
			bottom: 10px;
			left: 10px;

			border-top-left-radius: 30vh;
			border-top-right-radius: 30vh;

			border: 2px solid color(Teal, 0.5);

			@media (max-aspect-ratio: 1/1) {
				display: none;
			}

		}

		.logoImage {
			position: absolute;
			width: 30%;
			height: 0;
			padding-top: 8%;
			bottom: -18px;
			left: 35%;
			background: url("/images/st-germain.png") center center / contain no-repeat;
		}

		.frame-container {
			padding: 20px 10px 0;

			// Landscape
			@media (min-aspect-ratio: 1/1){
				display: none;
			}
		}
	}
	.textContent {
		height: 100%;		
		padding: 20% 12.5%;
		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.separator {
			position: relative;
		}

		.separator.top {
			height: 8px;
		}

		.separator.bottom {
			height: 6px;
		}

		.save {
			position: relative;
			color: color(DarkBlue);
		}

		.headerImage {
			background: center center / contain no-repeat;
			width: 60vw;
			height: 25vw;

			// Landscape
			@media (min-aspect-ratio: 1/1){
				width: 300px;
				// height: 125px;
				height: 13vh;
			}
			@media (max-aspect-ratio: 1/1) {
				width: 50vw;
				height: 20vw;
			}
		}
		sup {
			vertical-align: text-top;
			font-size: medium;
			@include tablet-up {
				position: relative;
				top: -0.05em;
			}
		}
		.step-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 90%;
			position: relative;
			@include tablet {
				width: 100%;
			}
		}
		.step-wrapper img {
			margin: 0 5px;
		}
		.step-wrapper img:last-child {
			transform: scaleX(-1);
		}
		h3 {
			white-space: pre-wrap;
			font-size: 2vh;
			// margin-bottom: 3vh;
			@include legacy-phone {
				font-size: 2vh;
			}
		}
		h4 {
			font-size: 16px;
			padding-top: 1vh;
			margin-bottom: 2vh;
			text-transform: uppercase;
			@include legacy-phone {
				font-size: 14px;
			}
		}
		h5 {
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	.cardFlowers {
		
		position: absolute;
		bottom: 14px;

		width: 50%;
		height: 200px;

		@media (max-aspect-ratio: 1/1) {
			display: none;
		}
		
	}

	.cardFlowers.left {
		left: 14px;
		background: url("/images/floral-left.png") left bottom / contain no-repeat;
	}

	.cardFlowers.right {
		right: 14px;
		background: url("/images/floral-right.png") right bottom / contain no-repeat;
	}

	.cornerFlowers {
		position: absolute;
		bottom: 0;
		width: 60%;
		height: 300px;
		opacity: 0;
		pointer-events: none;
		&.flowersR {
			right: 0;
			background: url("/images/floral-right.png") right bottom / contain no-repeat;
			transform: translate(20%, 20%) scale(1.2);
		}
		&.flowersL {
			left: 0;
			background: url("/images/floral-left.png") left bottom / contain no-repeat;
			transform: translate(-20%, 20%) scale(1.2);
		}
	}
	.flowersPortrait {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 51vw;
		display: none;
		background: url("/images/floral-lr.png") center top / cover no-repeat;
		transform: translateY(20%) scale(1.2);
	}
	.closeBtn {
		position: absolute;
		top: 0px;
		left: 2%;
		padding: 20px;
		cursor: pointer;
		display: none;
		img {
			width: 16px;
		}
	}
	#backBtn {
		position: absolute;
		left: 20px;
		top: 20px;
		cursor: pointer;
		opacity: 0;
		.backArrow {
			top:-20px;
			display: inline;
		}
		.backTxt {
			position: relative;
			padding-left: 10px;
			font-size: 20px;
			top: -6px;

			color: color(Black);
    
			@media (max-aspect-ratio: 1/1) {
				color: color(White);
			}

		}
	}
	#pinterestShareable {
		position: absolute;
		top: 0;
		left: 45%;
		width: 10%;
		height: auto;
		opacity: 0;
	}

	//////////////// Portrait (mobile)
	@media (max-aspect-ratio: 1/1){
		#recipeFrame {
			top: 0;
			width: 100%;
			height: 100%;
			.recipeBackground {
				border-radius: 0;
				box-shadow: none;
			}
			.logoImage {
				bottom: 7%;
				left: 30%;
				width: 40%;
				padding-top: 9%;
			}
		}
		.textContent {
			padding-bottom: 25vh;
		}
		.cornerFlowers {
			display: none;
		}
		.flowersPortrait {
			display: block;
		}
		#backBtn {
			display: none;
		}
		.closeBtn {
			display: block;
		}
	}
}
</style>