<template>
	<div id="legalTag" ref="self">{{ getLegalText }}</div>
</template>

<script>
import gsap from 'gsap';

export default {
	props: {
		prov: { type: String }
	},
	computed: {
		getLegalText() {
			return this.prov ? this.$t('legalCA') : this.$t('legal');
		}
	},
	mounted() {
		// Hide legal after 5 seconds
		gsap.to(this.$refs.self, {opacity: 0, display: "none", ease: "none", duration: 1, delay: 5});
	}
}
</script>

<style lang="scss">
@import '@/styles/variables';
#legalTag {
	position: absolute;
	bottom: 0;
	padding: 5px 0; 
	right: 0;
	width: 100%;
	white-space: pre;
	text-align: center;
	font-family: sans-serif;
	font-size: 8px;
	line-height: 1.2em;

	// Landscape
	@media (min-aspect-ratio: 1/1){

		font-size: 9px;
		font-size: 9px;
		bottom: 30vh;
		padding: 0;
		background: none;
	}
}
</style>